<template src="./Login.html"></template>
<style scoped lang="scss" src="./Login.scss"></style>

<script>
import LoginForm from "../../../../components/form/login/LoginForm";
import {ServiceFactory} from "@/services";
import {COOKIES} from "@/constants";

const AuthService = ServiceFactory.get('auth')
export default {
  name: 'Login',
  components: {LoginForm},
  data() {
    return {
      isLoading: false,
      error: ''
    }
  },
  methods: {
    async login(dataForm) {
      this.isLoading = true
      const data = {
        email: dataForm.email,
        password: dataForm.password
      }
      const loginResult = await AuthService.login(data).catch((error) => {
        this.error = error || ''
        return ''
      })
      this.isLoading = false
      if (!loginResult) return

      const {accessToken, roles} = loginResult
      if (accessToken) this.$cookies.set(COOKIES.accessToken, accessToken, `86400 s`)
      if (!isNaN(roles) ) this.$cookies.set(COOKIES.role, roles, `86400 s`)

      await this.$router.push({name: 'routes.home'})
    }
  },
}
</script>
