import { render, staticRenderFns } from "./LoginForm.html?vue&type=template&id=564ec6c2&scoped=true&"
import script from "./LoginForm.vue?vue&type=script&lang=js&"
export * from "./LoginForm.vue?vue&type=script&lang=js&"
import style0 from "./LoginForm.scss?vue&type=style&index=0&id=564ec6c2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "564ec6c2",
  null
  
)

export default component.exports