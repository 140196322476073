<template src="./LoginForm.html"></template>
<style scoped lang="scss" src="./LoginForm.scss"></style>

<script>
export default {
  name: 'Login',
  props: {
    isLoading: {
      require: true,
      default: false,
      type: Boolean
    },
    error: {
      require: false,
      default: '',
    }
  },
  data: function () {
    return {
      data: {
        email: '',
        password: ''
      },
      rules: {
        email: [
          {required: true, message: '必須項目です。', trigger: 'change'},
          {type: 'email', message: 'メールアドレスは無効です。', trigger: ['blur', 'change']}
        ],
        password: [
          {required: true, message: '必須項目です。', trigger: 'change'},
          {max: 36, message: 'パスワードは36文字以下に設定してください。', trigger: 'change'},
          {min: 6, message: 'パスワードは6文字以上に設定してください。', trigger: 'change'},
        ]
      }
    }
  },
  methods: {
    async onSubmit() {
      const valid = await this.$refs.form.validate()
      if (valid)
        this.$emit('submit', this.data)
    },
  }
}
</script>
